import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { adminTeamManagementAPI } from "../../../api/service";
import { IScheduler, ISchedulerListResponse, ITeamManagementTableData } from "../../../api/admin-module/team-management.model";

export interface ITeamManagementListSlice {
  teamListData: ITeamManagementTableData[] | null;
  isTeamExist: boolean;
  loading: boolean;
  error: any;
  status: string;
  schedulerList: ISchedulerListResponse;
}

const INITIAL_STATE = {
  teamListData: null,
  isTeamExist: false,
  loading: false,
  error: null,
  status: "idle",
  schedulerList: {},
} as ITeamManagementListSlice;

export const getTeamList = createAsyncThunk(
  "teamManagement/getTeamList",
  adminTeamManagementAPI.getTeamList
);

export const createTeam = createAsyncThunk(
  "teamManagement/createTeam",
  adminTeamManagementAPI.createTeam
);

export const updateTeam = createAsyncThunk(
  "teamManagement/updateTeam",
  adminTeamManagementAPI.updateTeam
);

export const updateTeamStatus = createAsyncThunk(
  "teamManagement/UpdateTeamStatus",
  adminTeamManagementAPI.updateTeamStatus
);

export const deleteTeam = createAsyncThunk(
  "teamManagement/deleteTeam",
  adminTeamManagementAPI.deleteTeam
);

export const checkTeamName = createAsyncThunk(
  "teamManagement/checkTeamName",
  adminTeamManagementAPI.checkTeamName
);

export const getSchedulerList = createAsyncThunk(
  "teamManagement/Searchscheduler",
  adminTeamManagementAPI.getSchedulerList
);

export const createUpdateTeamMembers = createAsyncThunk(
  "teammanagement/createupdateteammembers",
  adminTeamManagementAPI.createUpdateTeamMembers
);

export const saveDefaultHours = createAsyncThunk(
  "teammanagement/updateteamdefaulthours",
  adminTeamManagementAPI.updateTeamDefaultHours
);

export const saveNonWorkHours = createAsyncThunk(
  "teammanagement/updateweeknonworkhours",
  adminTeamManagementAPI.updateWeekNonWorkHours
);

const teamManagementSlice = createSlice({
  name: "teamManagement",
  initialState: INITIAL_STATE,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamList.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(getTeamList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.teamListData = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(getTeamList.rejected, (state, action) => {
        state.loading = false;
        state.teamListData = null;
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(createTeam.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(createTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
      })
      .addCase(createTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(updateTeam.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(updateTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
      })
      .addCase(updateTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(updateTeamStatus.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(updateTeamStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
      })
      .addCase(updateTeamStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(deleteTeam.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
      })
      .addCase(deleteTeam.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(checkTeamName.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(checkTeamName.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
        state.isTeamExist = action.payload.data;
      })
      .addCase(checkTeamName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(getSchedulerList.pending, (state) => {
        state.schedulerList.loading = true;
        state.schedulerList.status = "loading";
      })
      .addCase(getSchedulerList.fulfilled, (state, action) => {
        state.schedulerList.loading = false;
        state.schedulerList.error = null;
        state.schedulerList.status = "succeeded";
        state.schedulerList.data = action.payload.data;
      })
      .addCase(getSchedulerList.rejected, (state, action) => {
        state.schedulerList.loading = false;
        state.schedulerList.error = action.payload;
        state.schedulerList.status = "failed";
      })
      .addCase(createUpdateTeamMembers.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(createUpdateTeamMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
      })
      .addCase(createUpdateTeamMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(saveDefaultHours.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(saveDefaultHours.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
      })
      .addCase(saveDefaultHours.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      })
      .addCase(saveNonWorkHours.pending, (state) => {
        state.loading = true;
        state.status = "loading";
      })
      .addCase(saveNonWorkHours.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.status = "succeeded";
      })
      .addCase(saveNonWorkHours.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export default teamManagementSlice.reducer;
